import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Listing from "../components/Listing"
import Seo from "../components/Seo"

export default function Tag({ data }) {
  //Data destructuring
  const articlesData = data.articles.edges.map(edge => edge.node)
  const tagTitle = data.tag.edges[0].node.title
  const seo = data.seo

  return (
    <Layout>
      <Seo seoData={seo} />
      {/* Header */}
      <section className="section-header">
        <p className="section-caption">
          <span className="russian" lang="ru">
            {tagTitle.ru}
          </span>
          <span className="finnish" lang="fi">
            {tagTitle.fi}
          </span>
        </p>
        {/* <h1 className="section-title">
              <span className="russian" lang="ru">{tagTitle.ru}</span>
              <span className="finnish" lang="fi">{tagTitle.fi}</span>
          </h1> */}
      </section>

      {/* Articles Listing */}
      <Listing data={articlesData} pagesPerLoad={24} />
    </Layout>
  )
}

export const query = graphql`
  query GetTag($id: String) {
    tag: allSanityTag(filter: { _id: { eq: $id } }) {
      edges {
        node {
          title {
            fi
            ru
          }
        }
      }
    }
    articles: allSanityArticle(
      filter: { tags: { elemMatch: { _id: { eq: $id } } } }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          _id
          _type
          date
          slug {
            current
          }
          cover {
            asset {
              _id
            }
          }
          thumb {
            asset {
              _id
            }
          }
          title {
            fi
            ru
          }
          description {
            fi
            ru
          }
          author {
            name {
              ru
              fi
            }
          }
          section {
            title {
              ru
              fi
            }
            slug {
              current
            }
          }
        }
      }
    }
    seo: sanityTag(_id: { eq: $id }) {
      _id
      _type
      title {
        fi
        ru
      }
      slug {
        current
      }
    }
  }
`
